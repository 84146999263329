import React from "react"
import { Link } from "gatsby"

const HelloPage = () => {
    return (
        <section>
            <h1>Hello from Gatsby</h1>
            <p>Mermaid: {process.env.MERMAID_HOST}</p>
            <hr style= {{marginTop:'2em'}} />
            <Link to="/">Go back to the homepage</Link>
        </section>
    )
}

export default HelloPage